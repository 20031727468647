

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { observer } from "@/utils/common.util";
import {
  getDragonBoatFestival,
  IDragonType,
  joinDragonBoatFestival,
} from "@/api/activity.api";
import { Toast } from "vant";

@Component({
  components: {},
})
export default class DragonBoatFestival extends Vue {
  sweet_num = 0;
  salty_num = 0;
  rules = false;
  active: IDragonType = "";
  prevent = false;
  current_box_id = 0;
  tremble = false;
  againConfirm = false;
  map: Partial<Record<IDragonType, 0 | 1>> = {
    sweet: 0,
    salty: 1,
  };

  @Watch("rules")
  watchRules(val: boolean) {
    document.body.style.overflow = val ? "hidden" : "";
  }

  @Watch("tremble")
  watchTremble(val: boolean) {
    document.body.style.overflow = val ? "hidden" : "";
  }

  mounted() {
    observer("refreshInit", () => this.getData());
    this.getData();
  }

  async getData() {
    const row = await getDragonBoatFestival();
    this.sweet_num = row?.sweet_num || 0;
    this.salty_num = row?.salty_num || 0;
    this.current_box_id = row?.current_box_id || 0;
    const map: Record<string, IDragonType> = {
      "-1": "",
      "0": "sweet",
      "1": "salty",
    };
    this.active = map[String(row.current_camp)];
    if (row.current_camp !== -1) this.prevent = true;
  }

  async receiveAward() {
    const { active, prevent } = this;
    if (!active) {
      Toast({ message: "请选择阵营", position: "middle" });
      this.tremble = true;
      setTimeout(() => {
        this.tremble = false;
      }, 500);
      return;
    }
    if (!prevent) {
      this.againConfirm = true;
      return;
    }
    return this.$router.push({
      name: "BlindBoxDetailDragon",
      params: { id: String(this.current_box_id) },
      query: { sweet: String(Number(active === "sweet")) },
    });
  }

  async handleAgainConfirm() {
    const { active } = this;
    this.againConfirm = false;
    const toast = Toast.loading({ message: "加入阵营..." });
    await joinDragonBoatFestival(this.map[active]!);
    await this.getData();
    toast.close();
    Toast.success({ message: "已加入阵营" });
    return this.receiveAward();
  }

  handleDragonBtn(active: "sweet" | "salty") {
    if (this.prevent) {
      Toast({ message: "次日才能更换阵营", position: "middle" });
      return;
    }
    this.active = active;
  }

  get progressTotal() {
    const { sweet_num = 0, salty_num = 0 } = this;
    return sweet_num + salty_num || 0;
  }

  get sweetProgress() {
    return {
      "--sweet": `${((this.sweet_num || 0) / this.progressTotal) * 100 || 0}%`,
    };
  }

  get saltyProgress() {
    return {
      "--salty": `${((this.salty_num || 0) / this.progressTotal) * 100 || 0}%`,
    };
  }

  showRules() {
    this.rules = true;
  }
}
